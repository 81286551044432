// 未命名
import React, { useState, useEffect, useMemo } from 'react'
import styles from './jkr-form-page.module.scss'
import Header from '@/components/header/header'
import { Button, CascadePicker, Form, Input, Toast } from 'antd-mobile'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import activityApi from '@/apis/activity'
import cityDate from '@/assets/js/city'
import { PickerValue, PickerValueExtend } from 'antd-mobile/es/components/picker-view'
import indexApi from '@/apis'
import { debounce, Storage } from '@bihu/common-js'
import { useNavigate } from 'react-router-dom'
import { OptionType, creditSesameOptions } from '../../options'
import classNames from 'classnames'

interface Props {}

const JkrFormPage: React.FC<Props> = () => {
  // 表单
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const [haveCityData, setHaveCityData] = useState(false)
  const [cityValue, setCityValue] = useState<(string|null)[]>([])
  const [cityShow, setCityShow] = useState('')
  // 城市
  const [cityVisible, setCityVisible] = useState(false)
  // 真实姓名
  const [realName, setRealName] = useState('')
  // 年龄
  const [age, setAge] = useState<number>()
  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)
  const token = Storage.get('TOKEN') || ''
  // 芝麻分
  const [sesameCredit, setSesameCredit] = useState<number>()

  // 城市选择确认
  const cityConfirm = (value: PickerValue[], extend:PickerValueExtend) => {
    const cityName = extend.items.map(item => item?.label).join()
    setCityShow(cityName)
    const v = value[0]
    if (v === null) {
      return false
    }

    setCityValue(value)
    setHaveCityData(true)

    form.validateFields(['livingCity'])
  }

  // 居住城市验证
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkLivingCity = (_: any, value: string) => {
    if (!cityShow) {
      Toast.show('请选择居住城市')
      return Promise.reject(new Error('请选择居住城市'))
    }
    return Promise.resolve()
  }

  // 获取定位
  const openLocation = () => {
    let hasHandleCopyFunction = false
    // 开始执行位置授权
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          // 获取到的地理位置 position
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(position.coords.latitude, position.coords.longitude)
        },
        error => {
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(null, null)
        },
      )
    } else {
      getUserLocation(null, null)
    }
  }
  const getUserLocation = async(latitude:number|null, longitude:number|null) => {
    let params = latitude ? {
      location: `${latitude},${longitude}`,
      adChannelCode,
      coordtype: 'wgs84ll'
    } : {
      adChannelCode,
    }
    let res = await activityApi.reverseGeo(params)
    // res数据格式 例：{ province: 'xx', city: 'xx' }
    let currentProvince = cityDate.find(item => {
      return item.label === res?.data?.province
    })
    let currentCity
    if (currentProvince && currentProvince.children) {
      currentCity = currentProvince.children.find(cityItem => {
        return cityItem.label === res.data?.city
      })
    }
    // 筛选出来的城市 currentProvince, currentCity
    if (currentProvince && currentCity && !haveCityData) {
      const workCityCode = `${currentProvince.value},${currentCity.value}`
      const workCity = `${currentProvince.label},${currentCity.label}`
      setCityValue(workCityCode.split(','))
      setCityShow(workCity)
      setHaveCityData(true)
    }
  }

  // 匹配h5产品
  const geth5MatchRealization = async(code: string) => {
    try {
      const res = await indexApi.applyForH5Product(code)
      if (res.applyStatus === 1 && res?.thirdpartyTargetUrl) {
        window.location.href = res.thirdpartyTargetUrl
      } else {
        navigate(`/fill-information?adChannelCode=${adChannelCode}`)
      }
    } catch (error) {
      navigate(`/fill-information?adChannelCode=${adChannelCode}`) // 跳转到默认页面
    }
  }

  // 提交
  const confirm = () => {
    if (!realName) {
      return Toast.show('请输入真实姓名')
    }

    if (!/^[\u4e00-\u9fa5]+$/.test(realName)) {
      return Toast.show('真实姓名不能含有非中文字符')
    }

    if (!sesameCredit) {
      return Toast.show('请选择芝麻分')
    }
    form.validateFields().then(obj => {
      setBtnLoading(true)
      const params = {
        realName, // 真实姓名
        workCityCode: cityValue.join(), // 城市
        workCity: cityShow, // 城市
        age: obj.age, // 年龄
        sesameCredit
      }
      localStorage.setItem('fillInformation', JSON.stringify(params))
      geth5MatchRealization(adChannelCode)
    })
      .catch(error => {
        // eslint-disable-next-line no-console
        // console.log('error', error)
        const dom = document.getElementById('app')
        if (dom) {
          dom.scrollTo(0, 0)
        }
      })
      .finally(() => {
        setBtnLoading(false)
      })
  }

  const getFillData = () => {
    const params = {
      realName, // 真实姓名
      workCityCode: cityValue.join(), // 城市
      workCity: cityShow, // 城市
      age, // 年龄
      sesameCredit // 芝麻分
    }
    return params
  }

  // 保存上传
  const save = async(params:any) => {
    localStorage.setItem('fillInformation', JSON.stringify(params))
    try {
      await indexApi.tempSave(params)
    } catch (err) {
      console.log(err)
    }
  }

  // 回填数据
  const fillData = (info:any) => {
    // 工作城市
    if (info.workCityCode || info.workCity) {
      setHaveCityData(true)
      setCityValue(info.workCityCode.split(','))
      setCityShow(info.workCity)
    }

    // 真实姓名
    if (info.realName !== null) {
      setRealName(info.realName)
    }
    // 年龄
    if (info.age !== null) {
      setAge(info.age)
    }

    if (info.sesameCredit !== null) {
      setSesameCredit(info.sesameCredit)
    }

    // 赋值给表单
    form.setFieldsValue({
      realName: info.realName,
      age: info.age,
    })
  }


  // 表单各项变化监控(身份证除外)
  useEffect(() => {
    if (realName || age || cityShow || sesameCredit) {
      save(getFillData())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    realName,
    age,
    cityShow,
    sesameCredit
  ])

  useEffect(() => {
    openLocation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adChannelCode])

  useEffect(() => {
    async function getApplyStatus() {
      try {
        const res = await indexApi.getApplyStatus()
        indexApi.reportUserBehavior(1)
        fillData(res.data)
      } catch (error) {
        console.log('error', error)
      }
    }
    if (token) {
      getApplyStatus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <div className={styles.jkrFormPage}>
      <Header />
      <div className={styles.limitBox}>
        <div className={styles.limitBoxTitle}>最高可借额度（元）</div>
        <div className={styles.limitNum}> 10,000 </div>
        <div className={styles.splitLine}></div>
        <div className={styles.limitBoxFooter}>
          <div className={styles.footerItem}>
            <img className={styles.footerItemIcon} alt="safe1" src={require('@imgs/information/ic_safe-1.png')} />
            <span>年化利率7.2%起</span>
          </div>
          <div className={styles.footerItem}>
            <img className={styles.footerItemIcon} alt="safe2" src={require('@imgs/information/ic_safe-2.png')} />
            <span>随时可还款</span>
          </div>
          <div className={styles.footerItem}>
            <img className={styles.footerItemIcon} alt="safe3" src={require('@imgs/information/ic_safe-3.png')} />
            <span>额度灵活</span>
          </div>
        </div>
      </div>
      <Form
        form={form}
        layout="horizontal"
        mode="card"
      >
        <div className={styles.lastBoxItem}>
          <div className={styles.formHeader}>
            <img className={styles.formHeaderIcon} alt="safe-auth" src={require('@imgs/information/safe-auth.png')} />
            <div className={styles.formHeaderRight}>
              <div className={styles.formHeaderTitle}>官方认证，保护您的隐私安全</div>
              <div className={styles.formHeaderSubTitle}>您的身份证号码仅作真实性验证，我方不会另作他用</div>
            </div>
          </div>
          <div className={styles.formBox}>
            <Form.Item
              label="真实姓名"
              name="realName"
              rules={[
                {
                  validateTrigger: 'onblur',
                  required: true
                },
              ]}
            >
              <Input maxLength={4} value={realName} placeholder="请输入真实姓名" onBlur={val => {
                setRealName(val.target.value)
              }} />
            </Form.Item>
            <div className={styles.line}></div>
            <Form.Item
              label="年龄"
              name="age"
            >
              <Input
                placeholder="请输入年龄"
                maxLength={2}
                value={age?.toString()}
                onBlur={val => {
                  setAge(+val.target.value)
                }} />
            </Form.Item>
          </div>
        </div>
        <div className={styles.lastBoxItem}>
          <div className={styles.formHeader}>
            <img className={styles.formHeaderIcon} alt="address" src={require('@imgs/information/address.png')} />
            <div className={styles.formHeaderRight}>
              <div className={styles.formHeaderTitle}>当前所在地址</div>
              <div className={styles.formHeaderSubTitle}>请选择当前您所在的城市，提高匹配效率</div>
            </div>
          </div>
          <div className={styles.formBox}>
            <Form.Item
              label="当前所在城市"
              name="livingCity"
              trigger="onConfirm"
              className={styles.city}
              rules={[
                {
                  validator: checkLivingCity
                }
              ]}
              onClick={e => {
                setCityVisible(true)
              }}>
              <>
                <div className={styles.row} >
                  <Input placeholder="请选择城市" readOnly value={cityShow} />
                </div>
                <CascadePicker
                  options={cityDate}
                  visible={cityVisible}
                  onClose={() => {
                    setCityVisible(false)
                  }}
                  value={cityValue}
                  onConfirm={cityConfirm}
                />
              </>
            </Form.Item>
          </div>
          <div className={styles.formHeader}>
            <img className={styles.formHeaderIcon} alt="sesameCredit" src={require('@imgs/information/sesameCredit.png')} />
            <div className={styles.formHeaderRight}>
              <div className={styles.formHeaderTitle}>芝麻分</div>
              <div className={styles.formHeaderSubTitle}>请选择您的芝麻分，可提高借款额度</div>
            </div>
          </div>
          <div className={styles.formItemContent}>
            {
              creditSesameOptions.map((option:OptionType) => (
                <div
                  key={option.value + option.num}
                  className={classNames(styles.formItem, +option.value === sesameCredit && styles.activeItem)}
                  onClick={() => setSesameCredit(+option.value)}
                >
                  {option.label}
                </div>
              ))
            }
          </div>
        </div>
      </Form>
      <Button block loading={btnLoading} loadingText="机构匹配中" className={styles.btn} shape="rounded" color="primary" onClick={confirm}>
          提交借款
      </Button>
    </div>
  )
}

export default JkrFormPage
