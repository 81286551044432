// match-h5-animation
import React, { useEffect, useState } from 'react'
import styles from './match-h5-animation.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { TOKEN } from '@/constants/storage'
import { Storage } from '@bihu/common-js'
import indexApi from '@/apis'

interface Props {}

const MatchH5Animation: React.FC<Props> = () => {
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const [matchResult, setMatchResult] = useState<string | null>(null) // 保存匹配结果
  const [hasMatched, setHasMatched] = useState(false) // 用于记录是否已匹配成功[已匹配过则不再执行匹配逻辑
  const [isTimeout, setIsTimeout] = useState(false) // 用于记录是否已超过2秒
  const [search] = useSearchParams()
  const urlToken = search.get('token')
  const h5MatchRealization = search.get('h5MatchRealization') || 1
  const navigate = useNavigate()

  const geth5MatchRealization = async(code: string) => {
    if (+h5MatchRealization === 2) {
      return
    }
    try {
      const res = await indexApi.applyForH5Product(code)
      if (res.applyStatus === 1 && res?.thirdpartyTargetUrl) {
        setMatchResult(res.thirdpartyTargetUrl)
      } else {
        setMatchResult(null)
      }
    } catch (error) {
      setMatchResult(null) // 处理请求失败逻辑
    }
    setHasMatched(true)
  }

  useEffect(() => {
    let timer:any
    if (adChannelCode) {
      geth5MatchRealization(adChannelCode)
      // 设置 2 秒定时器
      timer = setTimeout(() => {
        setIsTimeout(true)
      }, 2000)
    }
    return () => clearTimeout(timer) // 清理定时器
  }, [adChannelCode])

  useEffect(() => {
    // 当匹配结果或超时状态更新时，执行跳转逻辑
    if (isTimeout && hasMatched && matchResult !== null && +h5MatchRealization === 1) {
      window.location.href = matchResult // 跳转到匹配到的产品
    } else if (isTimeout && hasMatched && matchResult === null && +h5MatchRealization === 1) {
      navigate(`/fill-information?adChannelCode=${adChannelCode}`) // 跳转到默认页面
    } else if (isTimeout && +h5MatchRealization === 2) {
      navigate(`/fill-information/jkr-form-page?adChannelCode=${adChannelCode}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeout, hasMatched, matchResult, navigate])

  // 处理联登逻辑
  useEffect(() => {
    if (urlToken) {
      Storage.set(TOKEN, urlToken)
      const url = window.location.href
      const updatedUrl = url.replace(/([?&])token=[^&]+(&|$)/, '$1')
      console.log('updatedUrl', updatedUrl)
      window.history.replaceState(null, 'null', updatedUrl)
    }
  }, [urlToken])

  return (
    <div className={styles.hengbeiLoading} >
      <div className={styles.loadingBox}>
        <img src={require('@/assets/imgs/gif/jkrLoading.gif')} alt="" />
        <span>正在评估您的借款申请...</span>
      </div>
    </div>
  )
}

export default MatchH5Animation
